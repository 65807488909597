import React from 'react';
import clsx from 'clsx';
import { TFunction, useTranslations } from 'src/renderless/useTranslations';
import Header from './Header';
import DescriptionList from './DescriptionList';
import DescriptionItem from './DescriptionItem';
import TransactionsTable from './TransactionsTable';
import Card from '../../atoms/Card';
import Tabs from '../../molecules/Tabs';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid';

export interface Props {
  className?: string;
  children?: React.ReactNode;
  accountData: any;
  overallSummary: any;
}

const useContent = (t: TFunction) =>
  ({
    account: {
      title: t(`obReports.account.title`),
      name: t(`obReports.account.name`),
      accountNumber: t(`obReports.account.accountNumber`),
      sortCode: t(`obReports.account.sortCode`),
      currency: t(`obReports.account.currency`),
      bankName: t(`obReports.account.bankName`),
      connectionTime: t(`obReports.account.connectionTime`),
      accountTransactions: t(`obReports.account.accountTransactions`),
      tabIncomingByGroups: t(`obReports.account.tabIncomingByGroups`),
      tabIncomingByDate: t(`obReports.account.tabIncomingByDate`),
      tabByGroups: t(`obReports.account.tabByGroups`),
      tabByDate: t(`obReports.account.tabByDate`),
      balance: t(`obReports.account.balance`),
      lowest6MonthBalance: t(`obReports.account.lowest6MonthBalance`),
      group: t(`obReports.account.group`),
      primaryGroup: t(`obReports.account.primaryGroup`),
    },
  } as any);

const getTransctionByGroupTables = (
  transactionsData: any,
  primaryGroupUUID: string,
  content: any,
) => {
  return transactionsData
    ? transactionsData.map((transactionData: any, idx: number) => {
        const isPrimaryGroup = primaryGroupUUID === transactionData.uuid;
        return (
          <div key={`transaction-list-${transactionData.name}`}>
            <div className="px-4 py-4 sm:px-6">
              <h5 className="leading-6 pb-4 font-small text-gray-800">
                {`${content.account.group} ${idx + 1} - ${transactionData.name}`}
              </h5>
              {isPrimaryGroup && (
                <h6 className="leading-6 pb-4 font-small text-pink-2">
                  {`- ${content.account.primaryGroup}`}
                </h6>
              )}
              <TransactionsTable
                groupReference={transactionData.name}
                transactionsGroup={transactionData.transactions}
                isPrimaryGroup={isPrimaryGroup}
              />
            </div>
          </div>
        );
      })
    : null;
};

const getTransctionByDateTables = (transactionsData: any) => {
  return transactionsData
    ? transactionsData.map((transactionData: any) => (
        <div key={`transaction-list-${transactionData.month}`}>
          <div className="px-4 py-4 sm:px-6">
            <h5 className="leading-6 pb-4 font-small text-gray-800">
              {`${transactionData.month}`}
            </h5>
            <TransactionsTable
              isPrimaryGroup={false}
              groupReference={transactionData.name}
              transactionsGroup={transactionData.transactions}
            />
          </div>
        </div>
      ))
    : null;
};

const OBAccountCard = ({ className, children, accountData, overallSummary, ...props }: Props) => {
  const { t } = useTranslations();
  const content = useContent(t);

  if (!accountData) {
    return null;
  }

  const tabs = [
    {
      name: content.account.tabIncomingByGroups,
      initiallyActive: true,
      component: getTransctionByGroupTables(
        accountData.incomingTransactionGroups,
        overallSummary.primaryGroupUUID,
        content,
      ),
    },
    {
      name: content.account.tabIncomingByDate,
      initiallyActive: false,
      component: getTransctionByDateTables(accountData.incomingTransactionGroupedByDate),
    },
    {
      name: content.account.tabByGroups,
      initiallyActive: false,
      component: getTransctionByGroupTables(
        accountData.transactionGroups,
        overallSummary.primaryGroupUUID,
        content,
      ),
    },
    {
      name: content.account.tabByDate,
      initiallyActive: false,
      component: getTransctionByDateTables(accountData.transactionGroupedByDate),
    },
  ];

  return (
    <div {...props} data-testid="obaccountcard" className={clsx('', className)}>
      <Card>
        <Header
          headingContent={content.account.title}
          subHeadingContent={accountData.accountType}
        />
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <DescriptionList>
            <DescriptionItem>
              <p className="text-sm font-medium text-gray-500">{content.account.name}</p>
              <p className="mt-1 text-sm text-gray-900 flex items-center">
                {`${accountData.name} `}
                <span className="pl-2">
                  {accountData.nameCheck ? (
                    <CheckCircleIcon className="h-5 w-5 text-green-600 mr-1 shrink-0" />
                  ) : (
                    <XIcon className="w-5 h-5 text-red-400" />
                  )}
                </span>
              </p>
            </DescriptionItem>
            <DescriptionItem>
              <p className="text-sm font-medium text-gray-500">
                {content.account.lowest6MonthBalance}
              </p>
              <p className="mt-1 text-xl text-gray-900">
                {accountData.lowest6MonthBalance || 'N/A'}
              </p>
            </DescriptionItem>
            <DescriptionItem className="sm:col-span-2">
              <p className="text-sm font-medium text-gray-500">{content.account.accountNumber}</p>
              <p className="mt-1 text-sm text-gray-900">{accountData.accountNumber}</p>
            </DescriptionItem>
            <DescriptionItem className="sm:col-span-2">
              <p className="text-sm font-medium text-gray-500">{content.account.sortCode}</p>
              <p className="mt-1 text-sm text-gray-900">{accountData.sortCode}</p>
            </DescriptionItem>
            <DescriptionItem className="sm:col-span-2">
              <p className="text-sm font-medium text-gray-500">{content.account.currency}</p>
              <p className="mt-1 text-sm text-gray-900">{accountData.currency}</p>
            </DescriptionItem>
            <DescriptionItem className="sm:col-span-2">
              <p className="text-sm font-medium text-gray-500">{content.account.bankName}</p>
              <p className="mt-1 text-sm text-gray-900">{accountData.bankName}</p>
            </DescriptionItem>
            <DescriptionItem className="sm:col-span-2">
              <p className="text-sm font-medium text-gray-500">{content.account.connectionTime}</p>
              <p className="mt-1 text-sm text-gray-900">{accountData.connectionTime}</p>
            </DescriptionItem>
          </DescriptionList>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <h4 className="text-md leading-6 font-medium text-gray-900">
            {content.account.accountTransactions}
          </h4>
          <Tabs tabs={tabs} />
        </div>
      </Card>
    </div>
  );
};

export default OBAccountCard;
