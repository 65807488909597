import React from 'react';
import clsx from 'clsx';

export interface Props {
  className?: string;
  children?: React.ReactNode;
  strokeClass?: string;
  fillClass?: string;
  small?: boolean;
}

const Spinner = ({ className, strokeClass, fillClass, small, ...props }: Props) => {
  const strokeClasses = clsx('stroke-current', strokeClass || 'text-blue-500');
  const fillClasses = clsx('fill-current', fillClass || 'text-blue-500');

  return (
    <svg
      data-testid="spinner"
      className={clsx(
        'animate-spin text-red-5',
        small ? 'w-6' : ' -ml-1 mr-3 h-24 w-24',
        className,
      )}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        className={clsx('opacity-25', strokeClasses)}
        cx="12"
        cy="12"
        r="10"
        strokeWidth="4"
        fillRule="evenodd"
      ></circle>
      <path
        fillRule="evenodd"
        className={clsx('opacity-75', fillClasses)}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export default Spinner;
