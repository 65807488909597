import React from 'react';
import clsx from 'clsx';

export interface Props {
  className?: string;
  headingContent: string;
  subHeadingContent?: string;
}

const Header = ({ className, headingContent, subHeadingContent, ...props }: Props) => {
  return (
    <div {...props} data-testid="cardheader" className={clsx('', className)}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{headingContent}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{subHeadingContent}</p>
      </div>
    </div>
  );
};

export default Header;
