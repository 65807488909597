import React from 'react';
import clsx from 'clsx';
import { TFunction, useTranslations } from 'src/renderless/useTranslations';
import DescriptionList from './DescriptionList';
import DescriptionItem from './DescriptionItem';
import Card from '../../atoms/Card';

export interface Props {
  className?: string;
  children?: React.ReactNode;
  obdata: any;
}

const useContent = (t: TFunction) =>
  ({
    accountSummary: {
      title: t(`obReports.accountSummary.title`),
      affordability: t(`obReports.accountSummary.affordability`),
      calculatedOBData: t(`obReports.accountSummary.calculatedOBData`),
      maxMonthlyAffordability: t(`obReports.accountSummary.maxMonthlyAffordability`),
      shareOfRent: t(`obReports.accountSummary.shareOfRent`),
      aggregateCalculatedIncome: t(`obReports.accountSummary.aggregateCalculatedIncome`),
      aggregateCalculatedSavings: t(`obReports.accountSummary.aggregateCalculatedSavings`),
      income: t(`obReports.accountSummary.income`),
      tenantData: t(`obReports.accountSummary.tenantData`),
      recurringAnnualIncome: t(`obReports.accountSummary.recurringAnnualIncome`),
      minAvailableSavings: t(`obReports.accountSummary.minAvailableSavings`),
    },
  } as any);

const OBSummaryCard = ({ className, children, obdata, ...props }: Props) => {
  const { t } = useTranslations();
  const content = useContent(t);

  if (!obdata) {
    return null;
  }

  return (
    <div {...props} data-testid="obsummarycard" className={clsx('', className)}>
      <Card>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <DescriptionList>
            <DescriptionItem descriptionTitle={content.accountSummary.affordability}>
              <p className="text-sm font-medium text-gray-500">
                {content.accountSummary.maxMonthlyAffordability}
              </p>
              <div className="w-0 flex-1 flex items-center">
                <p className="mt-1 text-xl text-gray-900">
                  {obdata.overallSummary.maxMonthlyAffordability || ''}
                </p>
              </div>
            </DescriptionItem>
            <DescriptionItem>
              <p className="text-sm font-medium text-gray-500">
                {content.accountSummary.shareOfRent}
              </p>
              <p className="mt-1 text-xl text-gray-900">
                {obdata.overallSummary.shareOfRent || ''}
              </p>
            </DescriptionItem>
            <DescriptionItem descriptionTitle={content.accountSummary.income}>
              <p className="text-sm font-medium text-gray-500">
                {content.accountSummary.recurringAnnualIncome}
              </p>
              <p className="mt-1 text-xl text-gray-900">
                {obdata.overallSummary.recurringAnnualIncome || ''}
              </p>
            </DescriptionItem>
            <DescriptionItem>
              <p className="text-sm font-medium text-gray-500">
                {content.accountSummary.minAvailableSavings}
              </p>
              <p className="mt-1 text-xl text-gray-900">
                {obdata.overallSummary.minAvailableSavings || ''}
              </p>
            </DescriptionItem>
          </DescriptionList>
        </div>
      </Card>
    </div>
  );
};

export default OBSummaryCard;
