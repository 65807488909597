import React from 'react';
import clsx from 'clsx';

export interface Props {
  className?: string;
  headingContent: string;
  subHeadingContent?: string;
  children?: React.ReactNode;
}

const PageHeader = ({
  className,
  children,
  headingContent,
  subHeadingContent,
  ...props
}: Props) => {
  return (
    <div {...props} data-testid="pageheader" className={clsx('', className)}>
      <div className="flex flex-wrap lg:space-x-10 flex-1 max-w-7xl mx-auto sm:px-2 md:px-8">
        <div>
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">
            {headingContent}
          </h2>
          <div className="flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="flex text-sm text-gray-500 sm:truncate">{subHeadingContent}</div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PageHeader;
