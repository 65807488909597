import React, { forwardRef, useMemo } from 'react';
import clsx from 'clsx';
import { IconProps } from 'src/components/types';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  id?: string;
  'data-testid'?: string;
  placeholder?: string;
  error?: React.ReactNode;
  iconLeft?: React.ComponentType<IconProps>;
  iconRight?: React.ComponentType<IconProps>;
  stubLeft?: React.ReactNode;
  stubRight?: React.ReactNode;
  value: React.HTMLInputTypeAttribute;
  integerOnly?: boolean;
}

const integerOnlyPreventedKeys = ['.', 'e', '-'];

const Input = forwardRef<any, Props>(
  (
    {
      className,
      type = 'text',
      error,
      disabled,
      iconLeft: IconLeft,
      iconRight: IconRight,
      stubRight,
      stubLeft,
      value = '',
      integerOnly,
      onChange,
      ...props
    },
    ref,
  ) => {
    const handleKeyDown = useMemo(
      () =>
        type === 'number' && integerOnly
          ? (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (integerOnlyPreventedKeys.includes(e.key)) {
                e.preventDefault();
                e.stopPropagation();
              }
            }
          : undefined,
      [integerOnly, type],
    );

    const preventNumberScroll = useMemo(
      () =>
        type === 'number'
          ? (e: React.WheelEvent<HTMLInputElement>) => {
              (e.target as HTMLElement).blur();
            }
          : undefined,
      [type],
    );

    const handleChange = useMemo(
      () =>
        integerOnly
          ? (e: React.ChangeEvent<HTMLInputElement>) => {
              //On mobile many keys come through as Unidentified/229 and cannot be prevented
              if (!/^[0-9]*$/.test(e.target.value)) {
                return;
              }
              onChange?.(e);
            }
          : onChange,
      [integerOnly, onChange],
    );

    return (
      <div
        data-testid="input-container"
        className={clsx(
          'w-full flex flex-row',
          'border box-border bg-white rounded focus:outline-none ',
          disabled
            ? 'border-grey-600 bg-grey-300 cursor-pointer'
            : error
            ? 'border-red-400 text-red-800 focus:ring-red-500'
            : 'border-grey-500 focus-within:border-blue-500 hover:border-blue-500',
          'transition duration-100 ease-in-out',
          className,
        )}
      >
        {stubLeft && (
          <div className="inline bg-grey-100 text-grey-600 px-4 py-3 rounded rounded-r-none border-r border-inherit">
            {stubLeft}
          </div>
        )}
        {IconLeft && (
          <span className="ml-4 self-center">
            <IconLeft className={clsx('h-6 w-6 ', disabled ? 'text-grey-600' : 'text-grey-900')} />
          </span>
        )}
        <input
          ref={ref}
          data-testid="input"
          disabled={disabled}
          aria-invalid={!!error}
          className={clsx(
            'w-full h-full px-4 py-2 rounded focus:outline-none text-grey-900',
            type === 'number' && 'spin-button-none',
          )}
          type={type}
          value={value}
          onKeyDown={handleKeyDown}
          onWheel={preventNumberScroll}
          onChange={handleChange}
          {...props}
        />
        {IconRight && (
          <span className="mr-4 self-center pointer-events-none">
            <IconRight className={clsx('h-6 w-6 ', disabled ? 'text-grey-600' : 'text-grey-500')} />
          </span>
        )}
        {stubRight && (
          <span className="bg-grey-100 text-grey-600 px-4 py-3 rounded rounded-l-none border-l border-inherit  pointer-events-none">
            {stubRight}
          </span>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
