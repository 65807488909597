import { useContext, createContext, useMemo } from 'react';
import { get } from 'lodash';
import defaultTranslations from 'public/translation.json';

export type TFunction = (key: string) => string | string[] | { [key: string]: string };

export const TranslationsContext = createContext(defaultTranslations);

export type TranslationsType = typeof defaultTranslations;

export const useTranslations = () => {
  const content = useContext<TranslationsType>(TranslationsContext);

  const t = useMemo(() => (key: string) => get(content, key), [content]);

  return { content, t };
};
