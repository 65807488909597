import React, { MouseEvent, useState } from 'react';
import clsx from 'clsx';

export interface TabProps {
  name: string;
  component: React.ReactNode;
}

export interface Props {
  className?: string;
  tabs: Array<{ name: string; initiallyActive: boolean; component: React.ReactNode }>;
}

const Tabs = ({ className, tabs, ...props }: Props) => {
  const [activeTab, setActiveTab] = useState<TabProps>(
    tabs.find((tab) => tab.initiallyActive) || tabs[0],
  );

  const onTabClickHandler = (event: MouseEvent<{ name: string }>) => {
    event.preventDefault();
    setActiveTab(tabs.find((tab) => tab.name === event.currentTarget.name) || tabs[0]);
  };

  return (
    <div {...props} data-testid="tabs" className={clsx('', className)}>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            defaultValue={activeTab.name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={onTabClickHandler}
                  name={tab.name}
                  className={clsx(
                    activeTab.name === tab.name
                      ? 'border-blue-500 text-blue-1'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                  )}
                  aria-current={activeTab.name === tab.name ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {activeTab.component}
    </div>
  );
};

export default Tabs;
