import React from 'react';
import clsx from 'clsx';

export interface Props {
  className?: string;
  children: React.ReactNode;
}

const DescriptionList = ({ className, children, ...props }: Props) => {
  return (
    <div {...props} data-testid="carddescriptionlist" className={clsx('', className)}>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">{children}</dl>
    </div>
  );
};

export default DescriptionList;
