import clsx from 'clsx';
import { IconProps } from 'src/components/types';

interface Props {
  className?: string;
  iconClassName?: string;
  testId?: string;
  onClick?: () => void;
  Icon: React.ComponentType<IconProps>;
}

const IconButton = ({ className, iconClassName, onClick, Icon, testId }: Props) => (
  <button
    data-testid={testId}
    className={clsx(
      'text-gray-700 border hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center dark:border-gray-500 dark:text-gray-500 dark:hover:text-white dark:focus:ring-gray-800',
      className,
    )}
    onClick={onClick}
  >
    <Icon className={clsx('w-5 h-5', iconClassName)} />
  </button>
);

export default IconButton;
