import React, { ElementType } from 'react';
import clsx from 'clsx';
import Spinner from '../Spinner';
import { IconProps } from 'src/components/types';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  as?: ElementType;
  href?: string;
  target?: string;
  className?: string;
  iconLeft?: React.ComponentType<IconProps>;
  iconRight?: React.ComponentType<IconProps>;
  children?: React.ReactNode;
  outline?: boolean;
  loading?: boolean;
}

const ButtonBase = ({
  as: Component = 'button',
  className,
  children,
  loading,
  iconLeft: IconLeft,
  iconRight: IconRight,
  ...props
}: ButtonProps) => {
  return (
    <Component
      {...props}
      data-testid="button"
      className={clsx(
        'px-3 md:px-8 py-2 rounded-sm',
        'flex flex-row items-center justify-center whitespace-nowrap',
        'focus:outline-none focus-visible:ring-2 font-semibold',
        'transition duration-100 ease-in-out',
        className,
      )}
    >
      {loading ? (
        <Spinner
          strokeClass="text-grey-4"
          fillClass="text-grey-4"
          className="!w-6 !h-6 !m-auto absolute left-0 right-0"
        />
      ) : null}
      <span className={clsx('contents', { invisible: loading })}>
        {IconLeft && (
          <span className="self-center">
            <IconLeft className="h-5 w-5 text-inherit" />
          </span>
        )}
        {children && <div className="mx-3">{children}</div>}
        {IconRight && (
          <span className="self-center">
            <IconRight className="h-5 w-5" />
          </span>
        )}
      </span>
    </Component>
  );
};

export default ButtonBase;
