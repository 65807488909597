/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

interface Props {
  totalResultsCount: number;
  countPerPage?: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const PaginationEllipsis = () => (
  <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
    ...
  </span>
);

const PaginationButton = ({
  onClick,
  page,
  active,
}: {
  onClick: (page: number) => void;
  page: number;
  active: boolean;
}) => (
  <button
    onClick={() => onClick(page)}
    aria-current="page"
    className={clsx(
      'bg-blue-50 border-gray-300 text-gray-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium',
      { ' border-blue-500 text-blue-600': active },
    )}
  >
    {page}
  </button>
);

const Pagination = ({
  totalResultsCount,
  currentPage,
  countPerPage = 50,
  setCurrentPage,
}: Props) => {
  const lastPage = Math.ceil(totalResultsCount / countPerPage);

  const goToNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const getVisiblePages = () => {
    const firstVisiblePage = currentPage < 4 ? 1 : currentPage - 2;

    const lastVisiblePage = currentPage <= lastPage - 2 ? currentPage + 2 : lastPage;

    const pageItems = [];

    if (firstVisiblePage > 1) {
      pageItems.push(
        <PaginationButton key={1} onClick={changePage} page={1} active={currentPage === 1} />,
      );
      if (firstVisiblePage > 2) {
        pageItems.push(<PaginationEllipsis key={'ellipsis-1'} />);
      }
    }

    for (let i = firstVisiblePage; i <= lastVisiblePage; i++) {
      pageItems.push(
        <PaginationButton key={i} onClick={changePage} page={i} active={currentPage === i} />,
      );
    }

    if (lastVisiblePage < lastPage) {
      if (lastVisiblePage < lastPage - 2) {
        pageItems.push(<PaginationEllipsis key={'ellipsis-2'} />);
      }

      pageItems.push(
        <PaginationButton
          key={lastPage}
          onClick={changePage}
          page={lastPage}
          active={currentPage === lastPage}
        />,
      );
    }

    return pageItems;
  };

  return (
    <div className="flex-1 flex items-center justify-end">
      <div>
        <nav className="inline-flex rounded-md shadow-sm" aria-label="Pagination">
          <button
            disabled={currentPage === 1}
            onClick={goToPrev}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>

          {getVisiblePages()}

          <button
            disabled={currentPage === lastPage}
            onClick={goToNext}
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
