import React from 'react';
import clsx from 'clsx';
import { TFunction, useTranslations } from 'src/renderless/useTranslations';

export interface Props {
  className?: string;
  groupReference: string;
  transactionsGroup: any[];
  isPrimaryGroup?: boolean;
}

const useContent = (t: TFunction) =>
  ({
    account: {
      date: t(`obReports.account.date`),
      description: t(`obReports.account.description`),
      amount: t(`obReports.account.amount`),
      balance: t(`obReports.account.balance`),
    },
  } as any);

const TransactionsTable = ({
  className,
  groupReference,
  transactionsGroup,
  isPrimaryGroup,
  ...props
}: Props) => {
  const { t } = useTranslations();
  const content = useContent(t);

  return (
    <div {...props} data-testid="cardheader" className={clsx('flex flex-col', className)}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            className={clsx(
              'shadow overflow-hidden sm:rounded-lg',
              isPrimaryGroup
                ? 'border border-pink-2 border-opacity-35'
                : 'border-gray-200 border-b',
            )}
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {content.account.date}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {content.account.description}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {content.account.amount}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {content.account.balance}
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactionsGroup.map((transaction, transactionIdx) => (
                  <tr
                    key={`${transaction.transaction_id}-${transactionIdx}`}
                    className={transactionIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {transaction.timestamp}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {transaction.description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {transaction.amount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {transaction.balance}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsTable;
