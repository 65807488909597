import React from 'react';
import clsx from 'clsx';

export interface Props {
  className?: string;
  descriptionTitle?: string;
  children: React.ReactNode;
}

const DescriptionItem = ({ className, descriptionTitle, children, ...props }: Props) => {
  return (
    <div {...props} data-testid="carddescriptionitem" className={clsx('', className)}>
      <dt className="mb-2 text-base font-medium text-500">{descriptionTitle}</dt>
      <dd className={clsx('text-sm text-gray-900')}>{children}</dd>
    </div>
  );
};

export default DescriptionItem;
