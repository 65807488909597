import Image from 'next/image';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { UserData } from 'src/types';
import Link from 'next/link';
import { generateHexColour } from 'src/utils/generateHexColour';

const navMenu = [
  { name: 'Dashboard', href: '/' },
  { name: 'Analytics', href: '/analytics' },
];

const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  { name: 'Log out', href: '/logout' },
];

export default function Header({
  user,
  currentPage,
}: {
  user: UserData | null;
  currentPage: 'Dashboard' | 'Analytics';
}) {
  return (
    <header>
      <nav className="px-4 sm:px-6 lg:px-8 border-b-2 border-gray-200 " aria-label="Top">
        <div className="w-full py-2 flex items-center justify-between">
          <div className="flex space-x-8">
            <div className="flex items-center space-x-4">
              <Image src={'/assets/homeppl-logomark.svg'} alt="Logo" width="30px" height="30px" />
              <span className="text-md font-medium">Homeppl Operations</span>
            </div>

            {navMenu.map((navItem) => (
              <Link href={navItem.href} key={navItem.name}>
                <a
                  className={clsx(
                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                    { '!border-blue-500 !text-gray-900': navItem.name === currentPage },
                  )}
                >
                  {navItem.name}
                </a>
              </Link>
            ))}
          </div>

          <div className="justify-end flex items-center space-x-2">
            {/* Profile dropdown */}
            <Menu as="div" className="relative flex-shrink-0">
              <Menu.Button
                id="profile-btn"
                className="h-8 w-8 bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="sr-only">Open user menu</span>

                {user?.firstName && user?.lastName ? (
                  <div
                    className="h-8 w-8 rounded-full flex items-center justify-center text-white font-semibold"
                    style={{
                      backgroundColor: generateHexColour(`${user.firstName}${user.lastName}`),
                    }}
                  >
                    {user.firstName[0]}
                    {user.lastName[0]}
                  </div>
                ) : (
                  <Image
                    className="h-8 w-8 rounded-full"
                    src="/assets/profile.png"
                    alt="Profile image"
                    layout="fill"
                  />
                )}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-20 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          href={item.href}
                          className={clsx(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700',
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
            <span className="text-sm font-medium text-gray-700">
              {user?.firstName} {user?.lastName}
            </span>
          </div>
        </div>
      </nav>
    </header>
  );
}
