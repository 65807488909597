import React from 'react';
import clsx from 'clsx';

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const Card = ({ className, children, ...props }: Props) => {
  return (
    <div {...props} data-testid="card" className={clsx('', className)}>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">{children}</div>
    </div>
  );
};

export default Card;
